<script setup>
import { useAnalyticsApi } from "~/composables/useAnalyticsApi";
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";

const { linkId } = defineProps({
  linkId: {
    type: Number,
    required: false,
  },
});

const route = useRoute();
const collectionId = route.params.id;

const { data, loading, fetchClicksData } = useAnalyticsApi();
const { selectedDateRange, filters, filtersValues } = storeToRefs(useAnalyticsStore());

const fetchData = () => fetchClicksData({ collectionId, linkId });

onMounted(fetchData);

watch([selectedDateRange, filters, filtersValues], fetchData);
</script>

<template>
  <div v-if="loading" class="loading-overlay fixed">
    <LoadersRingLoader />
  </div>
  <div
    class="grid-container grid-auto-rows remove-scroll"
    :class="{ 'overflow-hidden': loading }"
  >
    <div class="chart-item col-span-7">
      <ChartHourlySummary
        :data="data?.hour_wise_stats"
        :totalClicks="data?.total_clicks"
      />
    </div>
    <div class="chart-item col-span-5 lg:col-span-5 md:col-span-7">
      <ChartDailySummary :data="data?.day_wise_stats" />
    </div>
    <div class="chart-item col-span-2 lg:col-span-2 md:col-span-7">
      <ChartClicksRateSummary
        :totalClicks="data?.total_clicks"
        :uniqueClicks="data?.unique_clicks"
      />
    </div>
    <div class="chart-item col-span-3 md:col-span-7 lg:col-span-3">
      <ChartWeeklySummary
        :data="data?.weekday_wise_stats"
        :totalClicks="data?.total_clicks"
      />
    </div>
    <div class="chart-item col-span-4 md:col-span-7 lg:col-span-4">
      <ChartMonthlySummary
        :data="data?.month_wise_stats"
        :totalClicks="data?.total_clicks"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.loading-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
  height: 100%;
  width: 100%;
}

.grid-container {
  @apply grid grid-cols-7 gap-3 h-full relative overflow-y-auto;
}

.chart-item {
  @apply h-[28rem] border py-4 px-2 rounded-xl dark:bg-[#181818] dark:border-[#181818];
}
</style>
